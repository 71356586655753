import React from 'react';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import ImageContentLink from '../../../core/Components/ImageContentLink';
import ImageLink from '../../../core/Components/ImageLink';
import Link from '../../../core/Components/Link';
import { DefaultProps, PropTypes } from '../../../core/Utils/SitecoreFields';

const ProductFeatureCallout = ({ fields }) => {
  const {
    link,
    isSecondary,
    buttonOneImage,
    buttonTwoImage,
    buttonOneLink,
    buttonTwoLink,
  } = fields;
  const linkWrapped =
    !isExperienceEditorActive() &&
    link.value.href &&
    !buttonOneImage.value.src &&
    !buttonTwoImage.value.src;
  const buttonLinks = (
    <React.Fragment>
      {buttonOneImage.value.src && (
        <div className="btn-container">
          {buttonOneImage && (
            <ImageLink
              imageField={buttonOneImage}
              linkField={buttonOneLink}
              className="image-link"
            />
          )}
          {buttonTwoImage && (
            <ImageLink
              imageField={buttonTwoImage}
              linkField={buttonTwoLink}
              className="image-link"
            />
          )}
        </div>
      )}
    </React.Fragment>
  );

  return (
    <div
      className={`product-feature-callout ${
        isSecondary.value ? 'is-secondary' : ''
      }`}
    >
      {linkWrapped ? (
        <Link
          field={fields.link}
          title={fields.link.value.title || fields.title.value}
          className="l-padding"
        >
          <ImageContentLink fields={fields} headingTagType="h2" hasRichText>
            {buttonLinks}
          </ImageContentLink>
        </Link>
      ) : (
        <div className="l-padding">
          <ImageContentLink fields={fields} headingTagType="h2" hasRichText>
            {buttonLinks}
          </ImageContentLink>
        </div>
      )}
    </div>
  );
};

ProductFeatureCallout.propTypes = PropTypes.SitecoreItem.inject({
  ...PropTypes.ImageContentLink,
  isSecondary: PropTypes.Checkbox,
  buttonOneImage: PropTypes.Image,
  buttonOneLink: PropTypes.Link,
  buttonTwoImage: PropTypes.Image,
  buttonTwoLink: PropTypes.Link,
});

ProductFeatureCallout.defaultProps = DefaultProps.SitecoreItem.inject({
  ...DefaultProps.ImageContentLink,
  isSecondary: DefaultProps.Checkbox,
  buttonOneImage: DefaultProps.Image,
  buttonOneLink: DefaultProps.Link,
  buttonTwoImage: DefaultProps.Image,
  buttonTwoLink: DefaultProps.Link,
});

export default ProductFeatureCallout;
